/**
 * Common types
 *
 * @module
 */

// Explicitly defined constants are used because of Terser's inability to munge
// Typescript-generated enums
export const DepID_ApplicationEventsBeacon = 0
export const DepID_ClientDownloadSessionConfiguration = 1
export const DepID_ClientDownloadSessionConfigurationMessage = 2
export const DepID_ClientInitialization = 3
export const DepID_ClientInitialApplicationEventsBeacon = 4
export const DepID_ClientInitialSessionConfigSourceReceived = 5
export const DepID_ClientScheduleApplicationEventsBeaconInterval = 6
export const DepID_ClientScheduleMeasurementSchedulerRunInterval = 7
export const DepID_ClientScheduleSessionConfigurationRefreshInterval = 8
export const DepID_ClientSubsequentSessionConfigSourceReceived = 9
export const DepID_DNSClientInfoSamplePercentageCheck = 10
export const DepID_IntervalCalculator = 11
export const DepID_MeasurementSchedulerRun = 12
export const DepID_OpenInsightsFetch = 13
export const DepID_OpenInsightsPerformanceEntryManager = 14
export const DepID_PlatformTestObjectBeforeBeacon = 15
export const DepID_PlatformTestObjectBeforeTest = 16
export const DepID_PlatformTestObjectOnError = 17
export const DepID_PlatformTestObjectSendDopplerBeacon = 18
export const DepID_PlatformTestObjectSendPulsarBeacon = 19
export const DepID_RandomStringCharacterSelection = 20
export const DepID_SchedulerInitializePopulation = 21
export const DepID_SchedulerOutputDiagnosticsMessage = 22
export const DepID_SchedulerReschedule = 23
export const DepID_SchedulerScheduleNextMeasurement = 24
export const DepID_SchedulerScheduleNextMeasurementSoon = 25
export const DepID_SchedulerUpdatePopulation = 26
export const DepID_SelectWeightedRandomSample = 27

export type EpochDelta = number
export type SemVerComponents = [string, string, string]
export type ClientVersion = SemVerComponents

export type DeviceSpec = {
  type?: string
  vendor?: string
  model?: string
}

export type BrowserSpec = {
  name?: string
  version?: string
}

export type OSSpec = {
  name?: string
  version?: string
}

export type UAParserDevice = {
  type?: string
  vendor?: string
  model?: string
}

export type UAParserBrowser = {
  name?: string
  version?: string
}

export type UAParserOS = {
  name?: string
  version?: string
}

export type BrowserMetadata = {
  userAgent: string
  deviceCategory: string
  device: UAParserDevice
  browser: UAParserBrowser
  os: UAParserOS
}

/**
 * Local page overrides, mostly for developer use but also available for page owners
 * to use. Please update the
 * [wiki page](https://github.com/cbsinteractive/doppler.js/wiki/Local-Page-Settings)
 * with any changes to these properties.
 */
export type LocalPageSettings = {
  /**
   * The amount of time (in minutes) between application event beacons
   */
  applicationEventsBeaconInterval?: number
  /**
   * The URL used to send application event reports to.
   */
  applicationLoggingEndpoint?: string
  /**
   * If set, one of:
   *
   *  - off
   *  - error
   *  - warn
   *  - info
   *  - debug
   */
  consoleLogLevel?: string
  /**
   * The amount of time (in milliseconds) to wait before downloading session config.
   */
  sessionConfigDelay?: number
  /**
   * The amount of time (in seconds) to wait after receiving the session
   * configuration before beginning measurements.
   */
  measurementStartDelay?: number
  /**
   * If given, this is the amount of time (in minutes) to wait until
   * downloading a fresh session configuration.
   */
  sessionRefreshInterval?: number
  /**
   * If given, this overrides the endpoint to which Doppler.js sends beacons.
   */
  dopplerBeaconEndpoint?: string
  /**
   * Override the time to wait between measurement scheduler checks.
   */
  schedulerRunInterval?: number
  /**
   * An origin (i.e. scheme, domain and port) used to derive the URL for session
   * configuration downloads. A good use case is developers simulating platform
   * configuration changes.
   */
  sessionConfigOrigin?: string
  rumConfigCountryOverride?: string
  rumConfigStateOverride?: string
  rumConfigASNOverride?: number
}

export type DopplerEndpointsSource = {
  resource_timing: string
}

export type EndpointsSource = {
  application_events_base: string | null
  doppler: DopplerEndpointsSource
  pulsar: string
}

export type SessionMetadata = {
  browserMeta: BrowserMetadata
  clientVersion: ClientVersion
}

export enum ConsoleOutputLevel {
  /**
   * Output using `console.debug` if permitted by page settings
   */
  debug,
  /**
   * Output using `console.error` if permitted by page settings
   */
  error,
  /**
   * Output using `console.info` if permitted by page settings
   */
  info,
  /**
   * Output using `console.warn` if permitted by page settings
   */
  warn,
}

export type OutputToConsoleFunc = (
  messageLogLevel: ConsoleOutputLevel,
  callback: () => string,
) => void

export interface HasValue<T> {
  value: T
}
