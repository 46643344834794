import { v4 as uuidv4 } from "uuid"
import {
  ClientVersion,
  ConsoleOutputLevel,
  DepID_ClientInitialization,
  DepID_OpenInsightsPerformanceEntryManager,
  LocalPageSettings,
  SessionMetadata,
} from "./@types"
import { createApplicationEvents } from "./lib/applicationEvents"
import { run as clientRun } from "./lib/client"
import {
  getSessionConfigDelay,
  getSessionConfigURL,
} from "./lib/initialization"
import { createIntervalCalculator } from "./lib/intervalCalculator"
import { createMeasurementScheduler } from "./lib/measurementScheduler"
import { createPageSettings } from "./lib/pageSettings"
import { createRuntimeValues } from "./lib/runtimeValues"
import shouldLog from "./lib/shouldLog"
import { UAParser } from "./vendor/uaparser/ua-parser"
import version from "./version"
import { createPerformanceEntryManager } from "./lib/openinsights/resourceTiming"
import { createResettableEventCounter } from "./lib/resettableEventCounter"

interface MyWindow {
  __DOPPLER_PAGE_SETTINGS__?: LocalPageSettings
}

const myWindow = window as MyWindow
const pageSettings = createPageSettings(
  myWindow.__DOPPLER_PAGE_SETTINGS__ || {},
)
const newDate = (_depID: number) => new Date()
/**
 * Returns the number of milliseconds elapsed since midnight 1/1/1970 UTC.
 * Callers may prefer this to `newDate` if they know they only need the epoch
 * timestamp.
 */
const newEpochTimestamp = (_depID: number) => Date.now()
const newMonotonicTimestamp = (_depID: number) => performance.now()
const newRandomNumber = (_depID: number) => Math.random()
const callFetch = (url: string, options?: RequestInit) => fetch(url, options)
const callSendBeacon = (_depID: number, url: string) =>
  typeof navigator.sendBeacon === "function" ? navigator.sendBeacon(url) : false
const callSetInterval = (_depID: number, fn: TimerHandler, delay: number) => {
  const arbitraryMinimumDelay = 4800
  if (isNaN(delay) || delay < arbitraryMinimumDelay) {
    throw new Error(`setInterval called with invalid interval value ${delay}`)
  }
  return setInterval(fn, delay)
}
const callSetTimeout = (_depID: number, fn: TimerHandler, timeout: number) =>
  setTimeout(fn, timeout)

const localOutputToConsole = (
  messageLogLevel: ConsoleOutputLevel,
  message: string,
) => {
  /* eslint-disable no-console */
  if (messageLogLevel === ConsoleOutputLevel.error) {
    console.error(message)
  } else if (messageLogLevel === ConsoleOutputLevel.warn) {
    console.warn(message)
  } else if (messageLogLevel === ConsoleOutputLevel.info) {
    console.info(message)
  } else if (messageLogLevel === ConsoleOutputLevel.debug) {
    console.debug(message)
  }
  /* eslint-enable no-console */
}

const shouldOutputToConsole = (level: ConsoleOutputLevel) =>
  typeof pageSettings.consoleLogLevel === "string"
    ? shouldLog(pageSettings.consoleLogLevel, level)
    : false

const outputToConsole = (
  messageLogLevel: ConsoleOutputLevel,
  callback: () => string,
) => {
  if (shouldOutputToConsole(messageLogLevel)) {
    localOutputToConsole(messageLogLevel, callback())
  }
}

const runtimeValues = createRuntimeValues()
const maxTargetFrequency = { value: 0 }

const applicationEvents = createApplicationEvents(
  {
    callSendBeaconWithData: (_depID: number, url: string, data: BodyInit) =>
      typeof navigator.sendBeacon === "function"
        ? navigator.sendBeacon(url, data)
        : false,
    outputToConsole,
    runtimeValues,
  },
  // client version string
  version,
)

// Send one last application events beacon as the user leaves the page
window.addEventListener("beforeunload", () => {
  applicationEvents.beacon()
})

const performanceEntryManager = createPerformanceEntryManager({
  newDate: () => newDate(DepID_OpenInsightsPerformanceEntryManager),
  newPerformanceObserver: (callback: PerformanceObserverCallback) =>
    new PerformanceObserver(callback),
})

const performanceEntryAwaitTimeouts = createResettableEventCounter()
const intervalCalculator = createIntervalCalculator(
  {
    maxHourlyRate: maxTargetFrequency,
    newRandomNumber,
    outputToConsole,
  },
  [
    [5, 1000, 801, 850],
    [7, 1000, 851, 900],
    [9, 1000, 901, 950],
    [10, 1000, 951, 1000],
    [10, 1000, 1001, 1050],
    [9, 1000, 1051, 1100],
    [7, 1000, 1101, 1150],
    [5, 1000, 1151, 1200],
  ],
)
const measurementScheduler = createMeasurementScheduler({
  applicationEvents,
  callSetTimeout,
  callSendBeacon,
  callClearResourceTimings: () => performance.clearResourceTimings(),
  callClearTimeout: (timeoutID: number) => clearTimeout(timeoutID),
  callFetch,
  intervalCalculator,
  maxTargetFrequency,
  newAbortController: () => new AbortController(),
  newDate,
  newEpochTimestamp,
  newMonotonicTimestamp,
  newRandomNumber,
  outputToConsole,
  performanceEntryAwaitTimeouts,
  performanceEntryManager,
  runtimeValues,
})

detectFeatures() &&
  setTimeout(
    () =>
      clientRun(
        {
          addDocumentReadyStateChangeListener: (callback: () => void) =>
            document.addEventListener("readystatechange", callback),
          applicationEvents,
          callAbortSignalTimeout: (_depID: number, timeout: number) =>
            AbortSignal.timeout(timeout),
          callClearInterval: (_depID: number, intervalID: number) =>
            clearInterval(intervalID),
          callClearTimeout: (_depID: number, timeoutID: number): void =>
            clearTimeout(timeoutID),
          callFetch,
          callClearResourceTimings: () => performance.clearResourceTimings(),
          callSendBeacon,
          callSetInterval,
          callSetTimeout,
          getDocumentReadyState: (): DocumentReadyState => document.readyState,
          maxTargetFrequency,
          measurementScheduler,
          newAbortController: () => new AbortController(),
          newDate,
          newEpochTimestamp,
          newMonotonicTimestamp,
          newRandomNumber,
          newUUIDv4: () => uuidv4(),
          outputToConsole,
          performanceEntryAwaitTimeouts,
          performanceEntryManager,
          runtimeValues,
        },
        {
          sessionMetadata: newSessionMetadata(),
          sessionConfigURL: getSessionConfigURL(pageSettings),
          pageSettings,
          clientInitializationEvent:
            applicationEvents.recordClientInitializedEvent(
              newEpochTimestamp(DepID_ClientInitialization),
            ),
        },
      ),
    getSessionConfigDelay(5000, pageSettings),
  )

function newSessionMetadata() {
  const versionBits = version.split(".")
  if (
    versionBits.length != 3 ||
    Number.isNaN(parseInt(versionBits[0])) ||
    Number.isNaN(parseInt(versionBits[1]))
  ) {
    throw new Error(`Invalid client version: ${version}`)
  }
  const uaParser = new UAParser()
  const uaParserResult =
    typeof uaParser.getResult == "function"
      ? uaParser.getResult()
      : {
          ua: navigator.userAgent,
          device: {},
          browser: {},
          os: {},
        }
  const browserMeta = {
    userAgent: uaParserResult.ua,
    deviceCategory: "web",
    device: uaParserResult.device,
    browser: uaParserResult.browser,
    os: uaParserResult.os,
  }
  const [major, minor, patch] = versionBits
  const result: SessionMetadata = {
    browserMeta,
    clientVersion: [major, minor, patch] as ClientVersion,
  }
  return result
}

function detectFeatures() {
  return (
    typeof navigator.sendBeacon === "function" &&
    typeof Promise.allSettled === "function" &&
    typeof Array.prototype.flatMap === "function"
  )
}
