import {
  ConsoleOutputLevel,
  DepID_ClientDownloadSessionConfiguration,
  DepID_ClientDownloadSessionConfigurationMessage,
  DepID_ClientInitialApplicationEventsBeacon,
  DepID_ClientInitialSessionConfigSourceReceived,
  DepID_ClientScheduleApplicationEventsBeaconInterval,
  DepID_ClientScheduleMeasurementSchedulerRunInterval,
  DepID_ClientScheduleSessionConfigurationRefreshInterval,
  DepID_ClientSubsequentSessionConfigSourceReceived,
  HasValue,
  OutputToConsoleFunc,
  SessionMetadata,
} from "../@types"
import {
  ApplicationEvents,
  ClientInitialized,
  geoHeaderValues,
} from "./applicationEvents"
import { Config3Source, createConfig3 } from "./config3"
import { Scheduler } from "./measurementScheduler"
import { init } from "./openinsights/init"
import { PerformanceEntryManager } from "./openinsights/resourceTiming"
import { PageSettings } from "./pageSettings"
import {
  initializeScheduledPopulation,
  updateScheduledPopulation,
} from "./platformTestObjects"
import { ResettableEventCounter } from "./resettableEventCounter"
import { RuntimeValues } from "./runtimeValues"
import sampleDNSClientInfo from "./sampleDNSClientInfo"
import { SessionConfig } from "./sessionConfig"

export interface Dependencies {
  addDocumentReadyStateChangeListener: (callback: () => void) => void
  applicationEvents: ApplicationEvents
  /**
   * @param timeout Timeout in milliseconds
   */
  callAbortSignalTimeout: (depID: number, timeout: number) => AbortSignal
  callFetch: (url: string, options?: RequestInit) => Promise<Response>
  callSendBeacon: (depID: number, url: string) => boolean
  callSetInterval: (depID: number, fn: () => void, delay: number) => number
  callSetTimeout: (depID: number, fn: () => void, timeout: number) => number
  callClearInterval: (depID: number, intervalID: number) => void
  callClearTimeout: (depID: number, timeoutID: number) => void
  callClearResourceTimings: () => void
  getDocumentReadyState: () => DocumentReadyState
  maxTargetFrequency: HasValue<number>
  measurementScheduler: Scheduler
  newAbortController: () => AbortController
  newDate: (depID: number) => Date
  newEpochTimestamp: (depID: number) => number
  newMonotonicTimestamp: (depID: number) => number
  newRandomNumber: (depID: number) => number
  newUUIDv4: () => string
  outputToConsole: OutputToConsoleFunc
  performanceEntryAwaitTimeouts: ResettableEventCounter
  performanceEntryManager: PerformanceEntryManager
  runtimeValues: RuntimeValues
}

export type ClientInitializationInfo = {
  sessionMetadata: SessionMetadata
  pageSettings: PageSettings
  sessionConfigURL: string
  clientInitializationEvent: ClientInitialized
}

const newAppEventLoggingEndpointMessage = (
  appEventLoggingEndpoint: string | null,
) => `Application event logging endpoint: ${appEventLoggingEndpoint}`

export const run = (
  dependencies: Dependencies,
  clientInitInfo: ClientInitializationInfo,
) => {
  // Start engines
  const pageSettings = clientInitInfo.pageSettings
  downloadSessionConfiguration(
    dependencies,
    clientInitInfo,
    onInitialSessionConfigSourceReceived,
  ).then((sessionConfigInstance) => {
    if (sessionConfigInstance) {
      const runtimeValues = dependencies.runtimeValues
      scheduleSessionConfigurationRefreshInterval(
        dependencies,
        runtimeValues,
        sessionConfigInstance,
        clientInitInfo,
      )
      scheduleApplicationEventsBeaconInterval(
        dependencies,
        runtimeValues,
        sessionConfigInstance,
      )
      scheduleMeasurementSchedulerRunInterval(
        dependencies,
        runtimeValues,
        sessionConfigInstance,
      )
    }
  })

  function onInitialSessionConfigSourceReceived(
    asn: number,
    continentCode: string,
    countryCode: string,
    source: Config3Source,
  ) {
    const newSessionConfig = createConfig3(source, pageSettings)
    dependencies.runtimeValues.sessionConfigLastUpdatedAt =
      dependencies.newDate(DepID_ClientInitialSessionConfigSourceReceived)
    dependencies.applicationEvents.applicationEventLoggingEndpointBase =
      newSessionConfig.beaconEndpointInfo.applicationEventsBase
    dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
      newAppEventLoggingEndpointMessage(
        dependencies.applicationEvents.applicationEventLoggingEndpointBase,
      ),
    )
    dependencies.runtimeValues.disallowedNetworks =
      newSessionConfig.siteOwnerSettings.networksDisallowed
    dependencies.runtimeValues.updateMeasurementsEnabledForNetwork(asn)

    // Skip the opening session unless measurements are enabled
    if (dependencies.runtimeValues.measurementsEnabled) {
      const startDelay =
        typeof pageSettings.measurementStartDelay === "number"
          ? pageSettings.measurementStartDelay
          : newSessionConfig.siteOwnerSettings.measurementStartDelay

      // Schedule the opening session
      dependencies.callSetTimeout(
        DepID_ClientInitialSessionConfigSourceReceived,
        () => {
          init(
            dependencies.getDocumentReadyState,
            dependencies.addDocumentReadyStateChangeListener,
            0,
            [
              {
                fetchSessionConfiguration: () =>
                  Promise.resolve(newSessionConfig),
                produceExecutables,
              },
            ],
          ).then(() =>
            // Insert a slight delay in order to let the last beacon
            // complete. In most cases this avoids sending another
            // application event beacon on the interval.
            dependencies.callSetTimeout(
              DepID_ClientInitialApplicationEventsBeacon,
              () => dependencies.applicationEvents.beacon(),
              1000,
            ),
          )
        },
        startDelay * 1000,
      )
    } else {
      dependencies.outputToConsole(
        ConsoleOutputLevel.info,
        () => "Doppler measurements disabled (initial session config)",
      )
    }
    return newSessionConfig

    function produceExecutables(sessionConfigInstance: unknown) {
      return initializeScheduledPopulation(
        {
          applicationEvents: dependencies.applicationEvents,
          callClearTimeout: dependencies.callClearTimeout,
          callSendBeacon: dependencies.callSendBeacon,
          callSetTimeout: dependencies.callSetTimeout,
          callClearResourceTimings: dependencies.callClearResourceTimings,
          callFetch: dependencies.callFetch,
          maxTargetFrequency: dependencies.maxTargetFrequency,
          measurementScheduler: dependencies.measurementScheduler,
          newAbortController: dependencies.newAbortController,
          newDate: dependencies.newDate,
          newEpochTimestamp: dependencies.newEpochTimestamp,
          newMonotonicTimestamp: dependencies.newMonotonicTimestamp,
          newRandomNumber: dependencies.newRandomNumber,
          outputToConsole: dependencies.outputToConsole,
          performanceEntryAwaitTimeouts:
            dependencies.performanceEntryAwaitTimeouts,
          performanceEntryManager: dependencies.performanceEntryManager,
          runtimeValues: dependencies.runtimeValues,
        },
        clientInitInfo.sessionMetadata,
        pageSettings,
        /* eslint-disable @typescript-eslint/dot-notation */
        source["o"],
        /* eslint-enable @typescript-eslint/dot-notation */
        sessionConfigInstance as SessionConfig,
      )
    }
  }
}

const newApplicationEventsBeaconIntervalMessage = (interval: number) =>
  `Application events beacon interval ${interval} ms`

function scheduleApplicationEventsBeaconInterval(
  dependencies: Dependencies,
  runtimeValues: RuntimeValues,
  sessionConfigInstance: SessionConfig,
) {
  const interval =
    sessionConfigInstance.siteOwnerSettings
      .applicationEventsBeaconIntervalInMilliseconds
  if (!isNaN(interval)) {
    if (0 < interval) {
      dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
        newApplicationEventsBeaconIntervalMessage(interval),
      )
      runtimeValues.applicationEventsBeaconIntervalInMilliseconds = interval
      runtimeValues.applicationEventsBeaconIntervalID =
        dependencies.callSetInterval(
          DepID_ClientScheduleApplicationEventsBeaconInterval,
          () => dependencies.applicationEvents.beacon(),
          interval,
        )
    } else {
      runtimeValues.applicationEventsBeaconIntervalInMilliseconds = 0
    }
  }
}

const newSessionRefreshIntervalMessage = (interval: number) =>
  `Session refresh interval ${interval} ms`

function scheduleSessionConfigurationRefreshInterval(
  dependencies: Dependencies,
  runtimeValues: RuntimeValues,
  sessionConfigInstance: SessionConfig,
  clientInitInfo: ClientInitializationInfo,
) {
  const interval =
    sessionConfigInstance.siteOwnerSettings.sessionRefreshIntervalInMilliseconds
  if (!isNaN(interval)) {
    dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
      newSessionRefreshIntervalMessage(interval),
    )
    runtimeValues.sessionRefreshDelayInMilliseconds = interval
    runtimeValues.sessionConfigRefreshIntervalID = dependencies.callSetInterval(
      DepID_ClientScheduleSessionConfigurationRefreshInterval,
      () =>
        downloadSessionConfiguration(
          dependencies,
          clientInitInfo,
          newSubsequentSessionConfigSourceReceivedFunc(
            dependencies,
            clientInitInfo,
          ),
        ).then(
          newProcessNewSessionConfigurationFunc(dependencies, clientInitInfo),
        ),
      interval,
    )
  }
}

const newSchedulerRunIntervalMessage = (interval: number) =>
  `Scheduler run interval ${interval} ms`

function scheduleMeasurementSchedulerRunInterval(
  dependencies: Dependencies,
  runtimeValues: RuntimeValues,
  sessionConfigInstance: SessionConfig,
) {
  const interval =
    sessionConfigInstance.siteOwnerSettings.schedulerRunIntervalInMilliseconds
  if (!isNaN(interval)) {
    dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
      newSchedulerRunIntervalMessage(interval),
    )
    runtimeValues.schedulerRunIntervalInMilliseconds = interval
    runtimeValues.schedulerRunIntervalID = dependencies.callSetInterval(
      DepID_ClientScheduleMeasurementSchedulerRunInterval,
      () => dependencies.measurementScheduler.run(),
      interval,
    )
  }
}

const newDownloadingSessionConfigurationMessage = (
  newDate: (depID: number) => Date,
) =>
  `Downloading new session configuration (${newDate(
    DepID_ClientDownloadSessionConfigurationMessage,
  )})`

function downloadSessionConfiguration(
  dependencies: Dependencies,
  clientInitInfo: ClientInitializationInfo,
  handleSessionConfigSourceReceived: (
    asn: number,
    continentCode: string,
    countryCode: string,
    sessionConfigSource: Config3Source,
  ) => SessionConfig,
) {
  dependencies.outputToConsole(ConsoleOutputLevel.info, () =>
    newDownloadingSessionConfigurationMessage(dependencies.newDate),
  )
  const sessionConfigMonotonicStartTime = dependencies.newMonotonicTimestamp(
    DepID_ClientDownloadSessionConfiguration,
  )
  const beforeDownloadEvent =
    dependencies.applicationEvents.recordBeforeSessionConfigDownloadEvent(
      dependencies.newEpochTimestamp(DepID_ClientDownloadSessionConfiguration),
      dependencies.runtimeValues,
    )
  dependencies.runtimeValues.sessionStartTimestamp = dependencies.newDate(
    DepID_ClientDownloadSessionConfiguration,
  )
  dependencies.runtimeValues.sessionUUID = dependencies.newUUIDv4()
  dependencies.runtimeValues.resolverIP = undefined

  // Also need to implement completed and error event reporting
  return dependencies
    .callFetch(clientInitInfo.sessionConfigURL)
    .then((response) => response.json() as Promise<Config3Source>)
    .then((source) => sampleDNSClientInfo(dependencies, source))
    .then((source) => {
      // Freshen session config metadata
      /* eslint-disable @typescript-eslint/dot-notation */
      const asn = source["g"]["a"]
      const continentCode = source["g"]["con"]
      const countryCode = source["g"]["c"]
      const stateCode = source["g"]["s"]
      /* eslint-enable @typescript-eslint/dot-notation */
      dependencies.runtimeValues.lastReadSessConfHeaderContinent = continentCode
      dependencies.runtimeValues.lastReadSessConfHeaderCountry = countryCode
      dependencies.runtimeValues.lastReadSessConfHeaderState = stateCode
      dependencies.runtimeValues.lastReadSessConfHeaderAsn = asn.toString(10)
      dependencies.applicationEvents.recordAfterSessionConfigDownloadEvent(
        dependencies.newEpochTimestamp(
          DepID_ClientDownloadSessionConfiguration,
        ),
        dependencies.runtimeValues,
        Math.floor(
          dependencies.newMonotonicTimestamp(
            DepID_ClientDownloadSessionConfiguration,
          ) - sessionConfigMonotonicStartTime,
        ),
      )
      // Update geo for the early application events that wouldn't
      // have it yet.
      const geoHeaders = geoHeaderValues(dependencies.runtimeValues)
      beforeDownloadEvent.splice(1, 4, ...geoHeaders)
      clientInitInfo.clientInitializationEvent.splice(1, 4, ...geoHeaders)

      return handleSessionConfigSourceReceived(
        asn,
        continentCode,
        countryCode,
        source,
      )
    })
    .catch((e) => {
      // Prevent red stuff in the browser related to session config,
      // but anything here is probably worth immediate application
      // event logging
      if (e instanceof Error) {
        dependencies.applicationEvents.recordSessionConfigDownloadErrorEvent(
          dependencies.newEpochTimestamp(
            DepID_ClientDownloadSessionConfiguration,
          ),
          dependencies.runtimeValues,
          e.message,
        )
      }
      dependencies.applicationEvents.beacon()
    })
}

function newSubsequentSessionConfigSourceReceivedFunc(
  dependencies: Dependencies,
  clientInitInfo: ClientInitializationInfo,
) {
  return (
    asn: number,
    continentCode: string,
    countryCode: string,
    source: Config3Source,
  ) => {
    const newSessionConfig = createConfig3(source, clientInitInfo.pageSettings)
    dependencies.runtimeValues.sessionConfigLastUpdatedAt =
      dependencies.newDate(DepID_ClientSubsequentSessionConfigSourceReceived)
    dependencies.runtimeValues.disallowedNetworks =
      newSessionConfig.siteOwnerSettings.networksDisallowed
    dependencies.runtimeValues.updateMeasurementsEnabledForNetwork(asn)
    if (!dependencies.runtimeValues.measurementsEnabled) {
      dependencies.outputToConsole(
        ConsoleOutputLevel.info,
        () => "Doppler measurements disabled (subsequent session config)",
      )
    }
    updateScheduledPopulation(
      dependencies.measurementScheduler,
      clientInitInfo.sessionMetadata,
      /* eslint-disable @typescript-eslint/dot-notation */
      source["o"],
      /* eslint-enable @typescript-eslint/dot-notation */
      newSessionConfig.beaconEndpointInfo.dopplerEndpoints
        .dopplerResourceTimingEndpoint,
      newSessionConfig.beaconEndpointInfo.pulsarEndpointBase,
    )
    return newSessionConfig
  }
}

const newApplicationEventLoggingEndpointChangedMessage = (
  from: string | null,
  to: string | null,
) => `Application event logging endpoint changed from ${from} to ${to}`

const newApplicationEventLoggingIntervalChangedMessage = (
  from: number | undefined,
  to: number,
) =>
  `Application events beacon interval changed from ${from} to ${to} milliseconds`

const newSessionRefreshIntervalChangedMessage = (
  from: number | undefined,
  to: number,
) => `Session refresh interval changed from ${from} to ${to} milliseconds`

const newSchedulerRunIntervalChangedMessage = (
  from: number | undefined,
  to: number,
) => `Scheduler run interval changed from ${from} to ${to} milliseconds`

function newProcessNewSessionConfigurationFunc(
  dependencies: Dependencies,
  clientInitInfo: ClientInitializationInfo,
) {
  return (newSessionConfigInstance: SessionConfig | void) => {
    if (newSessionConfigInstance) {
      // Process any updates to the running session
      const runtimeValues = dependencies.runtimeValues
      // Application event logging endpoint
      if (
        newSessionConfigInstance.beaconEndpointInfo.applicationEventsBase !==
        dependencies.applicationEvents.applicationEventLoggingEndpointBase
      ) {
        dependencies.outputToConsole(ConsoleOutputLevel.info, () =>
          newApplicationEventLoggingEndpointChangedMessage(
            dependencies.applicationEvents.applicationEventLoggingEndpointBase,
            newSessionConfigInstance.beaconEndpointInfo.applicationEventsBase,
          ),
        )
        dependencies.applicationEvents.applicationEventLoggingEndpointBase =
          newSessionConfigInstance.beaconEndpointInfo.applicationEventsBase
      }
      // Application events beacon interval
      if (
        newSessionConfigInstance.siteOwnerSettings
          .applicationEventsBeaconIntervalInMilliseconds !==
        runtimeValues.applicationEventsBeaconIntervalInMilliseconds
      ) {
        dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
          newApplicationEventLoggingIntervalChangedMessage(
            runtimeValues.applicationEventsBeaconIntervalInMilliseconds,
            newSessionConfigInstance.siteOwnerSettings
              .applicationEventsBeaconIntervalInMilliseconds,
          ),
        )
        const intervalID = runtimeValues.applicationEventsBeaconIntervalID
        if (typeof intervalID === "number") {
          dependencies.callClearInterval(
            DepID_ClientScheduleApplicationEventsBeaconInterval,
            intervalID,
          )
        }
        scheduleApplicationEventsBeaconInterval(
          dependencies,
          runtimeValues,
          newSessionConfigInstance,
        )
      }
      // Session refresh interval
      if (
        newSessionConfigInstance.siteOwnerSettings
          .sessionRefreshIntervalInMilliseconds !==
        runtimeValues.sessionRefreshDelayInMilliseconds
      ) {
        dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
          newSessionRefreshIntervalChangedMessage(
            runtimeValues.sessionRefreshDelayInMilliseconds,
            newSessionConfigInstance.siteOwnerSettings
              .sessionRefreshIntervalInMilliseconds,
          ),
        )
        const intervalID = runtimeValues.sessionConfigRefreshIntervalID
        if (typeof intervalID === "number") {
          dependencies.callClearInterval(
            DepID_ClientScheduleSessionConfigurationRefreshInterval,
            intervalID,
          )
        }
        scheduleSessionConfigurationRefreshInterval(
          dependencies,
          runtimeValues,
          newSessionConfigInstance,
          clientInitInfo,
        )
      }
      // Scheduler run interval
      if (
        newSessionConfigInstance.siteOwnerSettings
          .schedulerRunIntervalInMilliseconds !==
        runtimeValues.schedulerRunIntervalInMilliseconds
      ) {
        dependencies.outputToConsole(ConsoleOutputLevel.debug, () =>
          newSchedulerRunIntervalChangedMessage(
            runtimeValues.schedulerRunIntervalInMilliseconds,
            newSessionConfigInstance.siteOwnerSettings
              .schedulerRunIntervalInMilliseconds,
          ),
        )
        const intervalID = runtimeValues.schedulerRunIntervalID
        if (typeof intervalID === "number") {
          dependencies.callClearInterval(
            DepID_ClientScheduleMeasurementSchedulerRunInterval,
            intervalID,
          )
        }
        scheduleMeasurementSchedulerRunInterval(
          dependencies,
          runtimeValues,
          newSessionConfigInstance,
        )
      }
    }
  }
}
